<template>
  <div class="Home" :style="'min-height:' + divHeight + 'px; overflow-y: auto;'">
    <div class="page-nav">
      <header>
        <template>
          <i>
            <img class="bars" src="../../assets/imgs/logo.png" alt />
          </i>
          <span>ID: {{ account_info.alias_id }}</span>
          <i>
            <van-popover v-model="showMenu" trigger="click" :actions="menuActions" @select="chooseMenuAction"
              theme="dark" placement="bottom-end">
              <template #reference>
                <van-button type="primary"><img class="members" src="../../assets/imgs/bars.png" alt /></van-button>
              </template>
            </van-popover>
            <!--  -->
          </i>
        </template>
      </header>
    </div>

    <div class="page-content bg-light-blue">
      <div class="card p-4 pt-6">
        <div class="panel-header">賬戶概況</div>

        <div class="card account-stock-overview mb-1 p-2">
          <div class="row">
            <div class="column column-6 p-1">
              <p class="text-center mini-text">持股量</p>
              <h3>{{ numberFormat(account_info.score) }} <small>股</small></h3>
            </div>
            <div class="column column-6 p-1">
              <p class="text-center mini-text">持股市價</p>
              <h3>{{ numberFormat(account_info.total_stock_value) }} <small>元</small></h3>
            </div>
          </div>
          <div class="row">
            <div class="column column-6 p-1">
              <p class="text-center mini-text">今日漲幅</p>
              <h2>
                <template v-if="account_info.today_stock_amplitude > 0">
                  <img src="../../assets/imgs/caret-green.png" class="mx-1" />
                  <span class="stock-green">{{ numberFormat(account_info.today_stock_amplitude) }} %</span>
                </template>
                <template v-if="account_info.today_stock_amplitude < 0">
                  <img src="../../assets/imgs/caret-red.png" class="mx-1" />
                  <span class="stock-red">{{ numberFormat(account_info.today_stock_amplitude) }} %</span>
                </template>
                <template v-if="!account_info.today_stock_amplitude">0 %</template>
              </h2>
            </div>
            <div class="column column-6 p-1">
              <p class="text-center mini-text">累積漲幅</p>
              <h2>
                <template v-if="account_info.total_stock_amplitude > 0">
                  <img src="../../assets/imgs/caret-green.png" class="mx-1" />
                  <span class="stock-green">{{ numberFormat(account_info.total_stock_amplitude) }} %</span>
                </template>
                <template v-if="account_info.total_stock_amplitude < 0">
                  <img src="../../assets/imgs/caret-red.png" class="mx-1" />
                  <span class="stock-red">{{ numberFormat(account_info.total_stock_amplitude) }} %</span>
                </template>
                <template v-if="!account_info.total_stock_amplitude">0 %</template>
              </h2>
            </div>
          </div>
        </div>

        <div class="panel-header mt-2">今日平臺經營數據</div>
        <div class="row my-0 mt-2">
          <div class="column-6 p-3 text-grey-blue">公司總盈利</div>
          <div class="column-6 p-3 text-right">
            <span class="text-blue">{{ unitNumberFormat(platform_info.total_stock_profit) }}</span>
            &nbsp;<strong class="mini-text">{{ unitFormat(platform_info.total_stock_profit) }}元</strong>
          </div>
        </div>
        <div class="row my-0 mt-2">
          <div class="column-6 p-3 text-grey-blue">總持股量</div>
          <div class="column-6 p-3 text-right">
            <span class="text-blue">{{ unitNumberFormat(platform_info.total_stock_score) }}</span>
            &nbsp;<strong class="mini-text">{{ unitFormat(platform_info.total_stock_score) }}股</strong>
          </div>
        </div>
        <div class="row my-0 mt-2">
          <div class="column-6 p-3 text-grey-blue">總持股金額</div>
          <div class="column-6 p-3 text-right">
            <span class="text-blue">{{ unitNumberFormat(platform_info.total_stock_value) }}</span>
            &nbsp;<strong class="mini-text"> {{ unitFormat(platform_info.total_stock_value) }}元</strong>
          </div>
        </div>
      </div>

      <div class="blank mb-4">
        <h1>平臺投資列表</h1>
      </div>

      <div v-for="(item, index) in stocks" :key="index" class="mb-3">
        <van-collapse v-model="activeStockItem[index]">
          <van-collapse-item name="1">
            <div slot="title">
              <img :src="item.logo_url" />
              {{ item.name }}
              <div class="button-finacial" @click.stop="openFinacialReport(item)">
                <van-icon name="chart-trending-o"/><span>財報</span>
              </div>
              <div class="button-trending" @click.stop="openStockChart(item)">
                <van-icon name="chart-trending-o"/><span>走勢圖</span>
              </div>
            </div>
            <div>
              <div class="card border border-radius-3 p-0">
                <h5 class="text-black fw-900 m-4">私募持股匯總數據</h5>
                <hr class="my-0" />
                <div class="row m-4">
                  <div class="column-6 p-1 text-left">
                    <p>私募總持股比例</p>
                  </div>
                  <div class="column-6 p-1 text-right text-black fw-900">
                    {{ item.score_ratio }} %
                  </div>
                </div>
                <div class="row m-4">
                  <div class="column-6 p-1 text-left">
                    <p>私募總持股量</p>
                  </div>
                  <div class="column-6 p-1 text-right">
                    <strong class="text-blue">{{ unitNumberFormat(item.total_raise_value) }}</strong> <span
                      class="mini-text text-black">{{ unitFormat(item.total_raise_value) }}</span>
                  </div>
                </div>
                <div class="row m-4">
                  <div class="column-6 p-1 text-left">
                    <p>私募總持股市值</p>
                  </div>
                  <div class="column-6 p-1 text-right">
                    <strong class="text-blue">{{ unitNumberFormat(item.total_raise_stock_value) }}</strong> <span
                      class="mini-text text-black">{{ unitFormat(item.total_raise_stock_value) }}</span>
                  </div>
                </div>
              </div>

              <div class="card border border-radius-3 p-0">
                <h5 class="text-black fw-900 m-4">公司財報數據</h5>
                <hr class="my-0" />
                <div class="row m-4">
                  <div class="column-6 p-1 text-left">
                    <p>發行總量</p>
                  </div>
                  <div class="column-6 p-1 text-right">
                    <strong class="text-blue">{{ unitNumberFormat(item.total_issuance) }}</strong> <span
                      class="mini-text text-black">{{ unitFormat(item.total_issuance) }}股</span>
                  </div>
                </div>
                <div class="row m-4">
                  <div class="column-6 p-1 text-left">
                    <p>發行價</p>
                  </div>
                  <div class="column-6 p-1 text-right text-black fw-900">
                    {{ item.issue_price }} 元
                  </div>
                </div>
                <div class="row m-4">
                  <div class="column-6 p-1 text-left">
                    <p>股票市價</p>
                  </div>
                  <div class="column-6 p-1 text-right text-black fw-900">
                    {{ item.stock_price }} 元
                  </div>
                </div>
                <div class="row m-4">
                  <div class="column-6 p-1 text-left">
                    <p>股票市值</p>
                  </div>
                  <div class="column-6 p-1 text-right">
                    <strong class="text-blue">{{ unitNumberFormat(item.stock_value) }}</strong> <span
                      class="mini-text text-black">{{ unitFormat(item.stock_value) }}元</span>
                  </div>
                </div>
                <div class="row m-4">
                  <div class="column-6 p-1 text-left">
                    <p>累計浮動</p>
                  </div>
                  <div class="column-6 p-1 text-right">
                    <strong class="stock-green" v-if="item.total_stock_amplitude > 0"><img
                        src="../../assets/imgs/caret-green.png" class="stock-flag" />&nbsp;{{ item.total_stock_amplitude
                      }}
                      %</strong>
                    <strong class="stock-red" v-if="item.total_stock_amplitude < 0"><img
                        src="../../assets/imgs/caret-red.png" class="stock-flag" />&nbsp;{{ item.total_stock_amplitude
                      }}
                      %</strong>
                    <strong class="text-blue" v-if="item.total_stock_amplitude == 0">{{ item.total_stock_amplitude }}
                      %</strong>
                  </div>
                </div>
              </div>


              <div class="card border border-radius-3 p-0">
                <h5 class="text-black fw-900 m-4">公司經營數據</h5>
                <hr class="my-0" />
                <div class="row m-4">
                  <div class="column-7 p-1 text-left">
                    <p>今日註冊用戶數</p>
                  </div>
                  <div class="column-5 p-1 text-right">
                    <strong class="text-blue">{{ unitNumberFormat(item.today_register_count) }}</strong> <span
                      class="mini-text text-black">{{ unitFormat(item.today_register_count) }}人</span>
                  </div>
                </div>
                <div class="row m-4">
                  <div class="column-7 p-1 text-left">
                    <p>今日成交訂單數</p>
                  </div>
                  <div class="column-5 p-1 text-right">
                    <strong class="text-blue">{{ unitNumberFormat(item.order_count) }}</strong> <span
                      class="mini-text text-black">{{ unitFormat(item.order_count) }}單</span>
                  </div>
                </div>
                <div class="row m-4">
                  <div class="column-7 p-1 text-left">
                    <p>今日營業額</p>
                  </div>
                  <div class="column-5 p-1 text-right">
                    <strong class="text-blue">{{ unitNumberFormat(item.today_order_revenue) }}</strong> <span
                      class="mini-text text-black">{{ unitFormat(item.today_order_revenue) }}元</span>
                  </div>
                </div>
                <div class="row m-4">
                  <div class="column-7 p-1 text-left">
                    <p>今日殺數</p>
                  </div>
                  <div class="column-5 p-1 text-right">
                    <strong class="text-blue">{{ unitNumberFormat(item.kill_day) }}</strong> <span
                      class="mini-text text-black">{{ unitFormat(item.kill_day) }}元</span>
                  </div>
                </div>
                <div class="row m-4">
                  <div class="column-7 p-1 text-left">
                    <p>今日新增一級代理數量</p>
                  </div>
                  <div class="column-5 p-1 text-right text-black fw-900">
                    {{ item.today_alliance_count }} 個
                  </div>
                </div>
                <div class="row m-4">
                  <div class="column-7 p-1 text-left">
                    <p>今日新增二級代理數量</p>
                  </div>
                  <div class="column-5 p-1 text-right text-black fw-900">
                    {{ item.today_club_count }} 個
                  </div>
                </div>
                <div class="row m-4">
                  <div class="column-7 p-1 text-left">
                    <p>駐留資金</p>
                  </div>
                  <div class="column-5 p-1 text-right">
                    <strong class="text-blue">{{ unitNumberFormat(item.pledge_fund_amount) }}</strong> <span
                      class="mini-text text-black"> {{ unitFormat(item.pledge_fund_amount) }}元</span>
                  </div>
                </div>
                <div class="row m-4">
                  <div class="column-7 p-1 text-left">
                    <p>保證金餘額</p>
                  </div>
                  <div class="column-5 p-1 text-right">
                    <strong class="text-blue">{{ unitNumberFormat(item.insurance_amount) }}</strong> <span
                      class="mini-text text-black"> {{ unitFormat(item.insurance_amount) }}元</span>
                  </div>
                </div>
                <div class="row m-4">
                  <div class="column-7 p-1 text-left">
                    <p>總用戶數</p>
                  </div>
                  <div class="column-5 p-1 text-right">
                    <strong class="text-blue">{{ unitNumberFormat(item.total_register_count + item.today_register_count) }}</strong> <span
                      class="mini-text text-black">{{ unitFormat(item.total_register_count + item.today_register_count) }}人</span>
                  </div>
                </div>
              </div>
            </div>
          </van-collapse-item>
        </van-collapse>
      </div>
    </div>

    <finacialreport :item="stockItem" />
    <chartlist :item="stockItem" />
  </div>
</template>

<script>
import { createIcon } from "@/utils/blockies";
import finacialreport from '../FinacialReport/finacial-report.vue';
import chartlist from '../Stock/chart-list.vue';
import { EventBus } from "../../event-bus";
export default {
  name: "Home",
  data() {
    return {
      divHeight:
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight,
      // wsClient: null,
      showMenu: false,
      showFinacialReport: false,
      showChartList: false,
      stockItem: {},
      menuActions: [{ value: 'logout', text: '登出' }],
      stocks: [],
      activeStockItem: [],
      platform_info: {
        total_profit: 0,
        total_stock_score: 0,
        total_stock_value: 0,
      },
      account_info: {
        "alias_id": 0,
        "nick_name": "",
        "score": 0,
        "today_score": 0,
        "total_score": 0,
        "login_times": 0,
        "last_login_ip": "",
        "last_login_at": "",
        "permission_level": 0,
        "can_edit_password": false,
        "can_edit_permission": false,
        "can_edit_scroe": false
      },
      account_thumb_url: '',
      account_alias_id: sessionStorage.getItem("stock_account_alias_id"),
      account_token: sessionStorage.getItem("stock_account_token"),
    };
  },
  components: {
    finacialreport,
    chartlist,
  },
  mounted() {

  },
  created() {
    let cache = sessionStorage.getItem("account_info");
    try {
      let r = JSON.parse(cache);
      if (r !== null) {
        this.account_info = r;
        this.account_thumb_url = createIcon(this.account_info.alias_id.toString());
      }
    } catch (e) {
      // nothing
    }

    this.getAccountDetail();
    this.getStockList();

    EventBus.$on("refresh-broadcast-information", data => {
      this.account_info.total_stock_value = data.account.total_stock_value;
      this.account_info.total_stock_amplitude = data.account.total_stock_amplitude;
      this.account_info.today_stock_amplitude = data.account.today_stock_amplitude;

      this.stocks = data.stocks;

      this.platform_info.total_stock_profit = data.platform.total_stock_profit;
      this.platform_info.total_stock_score = data.platform.total_stock_score;
      this.platform_info.total_stock_value = data.platform.total_stock_value;
    });

    EventBus.$on("refresh-chart-duration", data => {
      this.$api
        .getStockDetail({
          stock_id: data.stock_id,
          filter_type: data.filter_type,
          filter_duration: data.filter_duration,
        }).then(res => {
          EventBus.$emit(`update-chart-${data.filter_type}`, res.data);
        });
    });
  },
  methods: {
    createIcon,
    unitFormat(v) {
      if (v > 100000000) {
        return '億';
      } else if (v > 10000) {
        return '萬';
      }

      return '';
    },
    unitNumberFormat(v) {

      if (typeof v === 'undefined' || isNaN(v)) {
        v = 0;
      }

      let d = 1;
      if (v > 100000000) {
        // 億
        d = 100000000;
      // } else if (v > 1000000) {
      //   // 百萬
      //   d = 1000000;
      } else if (v > 10000) {
        // 萬
        d = 10000;
      }

      return this.numberFormat(v, d);
    },
    numberFormat(v, d) {

      if (typeof v === 'undefined' || isNaN(v)) {
        v = 0;
      }

      if (typeof d === 'undefined' || isNaN(d)) {
        d = 1;
      }

      let n = Math.floor(v / d * 100) / 100;

      return new Intl.NumberFormat().format(n);
    },
    renderThumb() {

    },
    logout() {
      this.$api
        .logout()
        .finally(() => {
          EventBus.$emit("logout-successfully");

          sessionStorage.removeItem("account_info");
          sessionStorage.removeItem("stock_account_alias_id");
          sessionStorage.removeItem("stock_account_token");

          this.$router.push({
            name: "login",
            path: `/login`,
          });
        });
    },
    chooseMenuAction(action) {
      switch (action.value) {
        case 'manage_users':
          this.$router.push({
            name: "search",
            path: `/search`,
          });
          break;
        case 'logout':
          this.logout();
          break;
      }
    },
    getAccountDetail() {
      this.$api
        .getAccountDetail({
          alias_id: this.account_alias_id,
        })
        .then(res => {
          if (res.status == 200) {
            EventBus.$emit('login-successfully');

            this.account_info = res.data;
            // this.account_thumb_url = createIcon(this.account_info.alias_id.toString());
            sessionStorage.setItem("account_info", JSON.stringify(res.data));

            if (this.account_info.permission_level > 0) {
              this.menuActions = [{ value: 'manage_users', text: '管理用戶' }, { value: 'logout', text: '登出' }];
            }
          }
        });
    },
    getStockList() {
      this.$api
        .getStockList()
        .then(res => {

          if (res.status == 200) {
            this.activeStockItem = [];
            res.data.forEach(() => {
              this.activeStockItem.push([]);
            })
            this.stocks = res.data;
          } else {
            //
          }
        });
    },
    openFinacialReport(item) {
      
      this.$api
        .getStockFinacialReport({
          stock_id: item.id,
        })
        .then(res => {
          this.stockItem = item;

          EventBus.$emit('show-finacialreport');

          EventBus.$emit('update-finacialreport', res.data);
        });
    },
    openStockChart(item) {
      // this.showChartList = true;

      this.$api
        .getStockDetail({
          stock_id: item.id,
        })
        .then(res => {
          this.stockItem = item;

          EventBus.$emit('show-chartlist');

          EventBus.$emit('update-chart-all', {
            pricelist: res.data.pricelist,
            orderlist: res.data.orderlist,
            registerlist: res.data.registerlist,
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.Home {
  width: 100%;
  color: #111827;

  .page-nav {
    width: 100%;
    height: 100px;

    header {
      width: 100%;
      height: 100px;
      padding: 0 30px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: white;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 1;

      .fan,
      i {
        width: 20px;
      }

      .members,
      .bars,
      i {
        width: 50px;
      }

      .van-popover__wrapper {
        .van-button {
          padding: 0;
          background-color: unset;
          border: unset;
        }
      }
    }
  }
}

.page-content {
  width: 100%;
  min-height: calc(100vh - 100px);
  padding: 1em;
}

.blank {
  margin: 2em 0;
}

h1 {
  font-size: 1.2em;
}

h5 {
  font-weight: 400;
  font-size: 1em;
  margin: 1em 0;
}

// i.van-icon.van-icon-orders-o {
//   color: #2563EB !important;
//   border: 1px solid #2563EB;
//   position: absolute;
//   right: 5em;
//   top: 1em;
//   font-weight: 900;
// }

.button-finacial {
  color: #2563EB !important;
  border: 1px solid #2563EB;
  border-radius: 0.5em;
  padding: 0.05em 0.2em;
  position: absolute;
  right: 7.5em;

  span {
    font-size: .85em;
  }
}

.button-trending {
  color: #2563EB !important;
  border: 1px solid #2563EB;
  border-radius: 0.5em;
  padding: 0.05em 0.2em;
  position: absolute;
  right: 3em;

  span {
    font-size: .85em;
  }
}
// i.van-icon.van-icon-chart-trending-o {
//   color: #2563EB !important;
//   border: 1px solid #2563EB;
//   position: absolute;
//   right: 3em;
//   top: 1em;
//   font-weight: 900;
// }

.van-popup {
  border-top-left-radius: 1em;
  border-top-right-radius: 1em;
}

.van-collapse-item__title {
  .van-cell__title div {
    align-items: center;
    display: flex;

    img {
      height: 1em;
      margin-right: .5em;
    }
  }
}

.van-popup {
  background-color: #FAFAFA;
}
</style>
import Vue from "vue";

export const EventBus = new Vue();

var oldtoken = null;
var wsClient = null;

EventBus.$on("login-successfully", () => {
  let token = sessionStorage.getItem("stock_account_token");

  if (wsClient !== null) {
    if (token != oldtoken) {
      wsClient.close();
      wsClient = null;
    } else{
      return;
    }
  }

  oldtoken = token;

  wsClient = new WebSocket(process.env.VUE_APP_WS_URL);

  wsClient.onopen = (e) => {
    console.log("open connection", e);

    wsClient.send(
      JSON.stringify({
        rid: `${Date.now()}-${Math.random()}-${Math.random()}`,
        action: "bind",
        content: token,
      })
    );
  };

  wsClient.onmessage = (event) => {
    let y = JSON.parse(event.data);

    if (!y.content) {
      return;
    }

    switch (y.action) {
      case "broadcast":
        EventBus.$emit("refresh-broadcast-information", y.content);
        break;
    }
  };

  wsClient.onerror = () => {
    setTimeout(() => {
      wsClient = null;
      EventBus.$emit("login-successfully", {});
    }, 2000);

    wsClient.close();
  };
});

EventBus.$on("logout-successfully", () => {
  if (wsClient === null) {
    return;
  }

  wsClient.close();
  wsClient = null;
});

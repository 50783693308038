<template>
  <van-popup v-model="showFinacialReport" position="bottom" :style="{ height: '90%' }">
    <div class="FinacialReport">
      <div class="page-nav">
        <header :style="{ 'z-index': 999 }">
          <template>
            <i>

            </i>
            <span> <img :src="item.logo_url" />{{ item.name }}</span>
            <i>
              <van-icon name="cross" @click="showFinacialReport = false" />
            </i>
          </template>
        </header>
      </div>
      <hr class="my-0" />
      <div class="page-content">
        <div class="card p-4 pb-1 border-radius-3">
          <span class="mini-text finacial-tabs bg-light-blue mb-4">
            <span class="text-center tab-item" :data-active="activeTab == 'main' ? 1 : 0"
            @click="activeTab = 'main';">主要指標</span>
            <span class="text-center tab-item" :data-active="activeTab == 'asset' ? 1 : 0"
            @click="activeTab = 'asset';">資產負債表</span>
            <span class="text-center tab-item" :data-active="activeTab == 'profit' ? 1 : 0"
            @click="activeTab = 'profit';">利潤表</span>
            <span class="text-center tab-item" :data-active="activeTab == 'cash' ? 1 : 0"
            @click="activeTab = 'cash';">現金流量表</span>
          </span>

          <div class="row my-0 mini-text">
            <div class="column-6 p-1 ">&nbsp;</div>
            <div class="column-3 p-1 text-right text-grey-blue">{{ subjects[0] }}</div>
            <div class="column-3 p-1 text-right text-grey-blue">{{ subjects[1] }}</div>
          </div>
          <template v-for="(t, flag) in tab">
            <div :key=flag :style="{ display: activeTab == flag ? '' : 'none'}">
              <template v-for="(opt, idxopt) in t.options">
                <div class="panel-header text-left medium-text" :key="idxopt +'-1'">{{ opt.title }}</div>
                <div class="mb-4" :key="idxopt + '-2'">
                  <template v-if="opt.children.length == 0">
                    <div class="row my-0 mt-1 mini-text">
                      <div class="column-6 p-1 text-grey-blue">&nbsp;</div>
                      <div class="column-3 p-1 text-right">
                        <span class="text-blue">{{ displayLeftRecordValue(opt.name) }}</span>
                        &nbsp;<span class="mini-text">{{ displayLeftRecordUnit(opt.name) }}</span>
                      </div>
                      <div class="column-3 p-1 text-right">
                        <span class="text-blue">{{ displayRightRecordValue(opt.name) }}</span>
                        &nbsp;<span class="mini-text">{{ displayRightRecordUnit(opt.name) }}</span>
                      </div>
                    </div>
                  </template>
                  <template v-for="(child, idxchild) in opt.children">
                    <div class="row my-0 mt-1 mini-text" :key="idxchild +'-'+ idxopt">
                      <div class="column-6 p-1 text-grey-blue">{{ child[0] }}</div>
                      <div class="column-3 p-1 text-right">
                        <span class="text-blue">{{ displayLeftRecordValue(child[1], child[2]) }}</span>
                        &nbsp;<span class="mini-text">{{ displayLeftRecordUnit(child[1], child[2]) }}</span>
                      </div>
                      <div class="column-3 p-1 text-right">
                        <span class="text-blue">{{ displayRightRecordValue(child[1], child[2]) }}</span>
                        &nbsp;<span class="mini-text">{{ displayRightRecordUnit(child[1], child[2]) }}</span>
                      </div>
                    </div>
                  </template>
                </div>
              </template>
            </div>
          </template>
        </div>
      </div>
    </div>
  </van-popup>
</template>

<script>
import { EventBus } from '../../event-bus';

export default {
  name: "FinacialReport",
  props: {
    item: {
      required: true,
      default: function () {
        return {};
      },
    },
  },
  data() {

    return {
      showFinacialReport: false,
      subjects: ['', ''],
      records: {},
      activeTab: 'main',
      tab: {
        main: {
          title: '主要指標',
          filter_flag: 'main',
          options: [
            {
              title: '每股指標',
              children: [
                ['基本每股收益(元)', 'main_basic_eps', '$'],
                ['扣非每股收益(元)', 'main_adjusted_eps', '$'],
                ['稀釋每股收益(元)', 'main_diluted_eps', '$'],
                ['每股凈資產(元)', 'main_net_asset_per_share', '$'],
                ['每股資本公積(元)', 'main_capital_reserve_per_share', '$'],
                ['每股未分配利潤(元)', 'main_undistributed_profit_per_share', '$'],
                ['每股經營現金流(元)', 'main_operating_cash_flow_per_share', '$'],
              ],
            },
            {
              title: '成長能力指標',
              children: [
                ['營業總收入(元)', 'main_total_revenue', '$'],
                ['毛利潤(元)', 'main_gross_profit', '$'],
                ['歸屬凈利潤(元)', 'main_net_profit', '$'],
                ['扣非凈利潤(元)', 'main_adjusted_net_profit', '$'],
                ['營業總收入同比增長', 'main_total_revenue_yoy_growth', '%'],
                ['歸屬凈利潤同比增長', 'main_net_profit_yoy_growth', '%'],
                ['扣非凈利潤同比增長', 'main_adjusted_net_profit_yoy_growth', '%'],
                ['單季度營業總收入同比', 'main_quarterly_total_revenue_yoy', '%'],
                ['單季度歸屬凈利潤同比', 'main_quarterly_net_profit_yoy', '%'],
                ['單季度扣非凈利潤同比', 'main_quarterly_adjusted_net_profit_yoy', '%'],
                ['單季度營業總收入環比', 'main_quarterly_total_revenue_qoq', '%'],
                ['單季度歸屬凈利潤環比', 'main_quarterly_net_profit_qoq', '%'],
                ['單季度扣非凈利潤環比', 'main_quarterly_adjusted_net_profit_qoq', '%'],
              ],
            },
            {
              title: '盈利能力指標',
              children: [
                ['凈資產收益率(加權)', 'main_roe_weighted', '%'],
                ['凈資產收益率(扣非/加權)', 'main_adjusted_roe_weighted', '%'],
                ['總資產收益率(加權)', 'main_roa_weighted', '%'],
                ['投入資本回報率', 'main_return_on_invested_capital', '%'],
                ['毛利率', 'main_gross_margin', '%'],
                ['凈利率', 'main_net_margin', '%'],
              ],
            },
            {
              title: '收益質量指標',
              children: [
                ['銷售凈現金流/營業收入', 'main_sales_cash_flow_to_revenue', '$'],
                ['經營凈現金流/營業收入', 'main_operating_cash_flow_to_revenue', '$'],
                // ['實際稅率', 'main_effective_tax_rate', '%'],
              ],
            },
            {
              title: '財務風險指標',
              children: [
                ['流動比率', 'main_current_ratio', '$'],
                ['速動比率', 'main_quick_ratio', '$'],
                ['現金流量比率', 'main_cash_flow_ratio', '$'],
                ['資產負債率', 'main_debt_to_asset_ratio', '%'],
                ['權益乘數', 'main_equity_multiplier', '$'],
                ['產權比率', 'main_equity_ratio', '$'],
              ],
            },
            {
              title: '營運能力指標',
              children: [
                ['總資產周轉天數(天)', 'main_total_asset_turnover_days', '$'],
                ['存貨周轉天數(天)', 'main_inventory_turnover_days', '$'],
                ['應收賬款周轉天數(天)', 'main_receivables_turnover_days', '$'],
                ['總資產周轉率(次)', 'main_total_asset_turnover', '$'],
                ['存貨周轉率(次)', 'main_inventory_turnover', '$'],
                ['應收賬款周轉率(次)', 'main_receivables_turnover', '$'],
              ],
            },
          ],
        },
        asset: {
          title: '資產負債表',
          filter_flag: 'asset',
          options: [
            {
              title: '流動資產',
              children: [
                ['貨幣資金', 'asset_cash_and_cash_equivalents', '$'],
                ['拆出資金', 'asset_loans_and_advances', '$'],
                ['交易性金融資產', 'asset_trading_financial_assets', '$'],
                ['應收票據及應收賬款', 'asset_notes_and_accounts_receivable', '$'],
                ['其中：應收票據', 'asset_notes_receivable', '$'],
                ['應收賬款', 'asset_accounts_receivable', '$'],
                ['預付款項', 'asset_prepayments', '$'],
                ['其他應收款合計', 'asset_other_receivables', '$'],
                ['買入返售金融資產', 'asset_financial_assets_purchased', '$'],
                ['存貨', 'asset_inventory', '$'],
                ['其他流動資產', 'asset_other_current_assets', '$'],
              ],
            },
            {
              title: '流動資產合計',
              children: [],
              name: 'asset_total_current_assets',
            },
            {
              title: '非流動資產',
              children: [
                // ['發放委托貸款及墊款', 'asset_entrusted_loans_and_advances'],
                // ['債權投資', 'asset_debt_investments'],
                // ['其他非流動金融資產', 'asset_other_non_current_financial_assets'],
                // ['投資性房地產', 'asset_investment_properties'],
                ['固定資產', 'asset_fixed_assets', '$'],
                // ['在建工程', 'asset_construction_in_progress'],
                // ['使用權資產', 'asset_right_of_use_assets'],
                // ['無形資產', 'asset_intangible_assets'],
                ['開發支出', 'asset_development_expenditures', '$'],
                ['長期待攤費用', 'asset_long_term_deferred_expenses', '$'],
              ],
            },
            {
              title: '非流動資產合計',
              children: [],
              name: 'asset_total_non_current_assets',
            },
            {
              title: '資產總計',
              children: [],
              name: 'asset_total_assets',
            },
            {
              title: '流動負債',
              children: [
                ['吸收存款及同業存放', 'asset_deposits_and_interbank', '$'],
                ['應付票據及應付賬款', 'asset_notes_and_accounts_payable', '$'],
                ['其中：應付賬款', 'asset_accounts_payable', '$'],
                ['合同負債', 'asset_contract_liabilities', '$'],
                ['應付職工薪酬', 'asset_employee_compensation_payable', '$'],
                // ['應交稅費', 'asset_taxes_payable', '$'],
                ['其他應付款合計', 'asset_other_payables', '$'],
                ['其中：應付股利', 'asset_dividends_payable', '$'],
                ['一年內到期的非流動負債', 'asset_current_portion_of_non_current_liabilities', '$'],
                ['其他流動負債', 'asset_other_current_liabilities', '$'],
              ],
            },
            {
              title: '流動負債合計',
              children: [],
              name: 'asset_total_current_liabilities',
            },
            {
              title: '非流動負債',
              children: [
                ['租賃負債', 'asset_lease_liabilities', '$'],
                // ['遞延所得稅負債', 'asset_deferred_tax_liabilities', '$'],
              ],
            },
            {
              title: '非流動負債合計',
              children: [],
              name: 'asset_total_non_current_liabilities',
            },
            {
              title: '負債合計',
              children: [],
              name: 'asset_total_liabilities',
            },
            {
              title: '所有者權益(或股東權益)',
              children: [
                ['實收資本(或股本)', 'asset_paid_in_capital', '$'],
                ['資本公積', 'asset_capital_reserve', '$'],
                ['其他綜合收益', 'asset_other_comprehensive_income', '$'],
                ['盈餘公積', 'asset_surplus_reserve', '$'],
                ['一般風險準備', 'asset_general_risk_reserve', '$'],
                ['未分配利潤', 'asset_undistributed_profits', '$'],
              ],
            },
            // {
            //   title: '歸屬於母公司股東權益合計',
            //   children: [
            //     ['少數股東權益', 'asset_minority_interests'],
            //   ],
            // },
            // {
            //   title: '股東權益合計',
            //   children: [],
            //   name: 'asset_total_equity',
            // },
            // {
            //   title: '負債及股東權益總計',
            //   children: [
            //     ['審計意見(境內)', 'asset_audit_opinion_domestic'],
            //   ],
            // },
          ],
        },
        profit: {
          title: '利潤表',
          filter_flag: 'profit',
          options: [
            {
              title: '營業總收入',
              children: [
                ['營業收入', 'profit_operating_revenue', '$'],
                ['利息收入', 'profit_interest_revenue', '$'],
              ],
            },
            {
              title: '營業總成本',
              children: [
                ['營業成本', 'profit_operating_costs', '$'],
                ['利息支出', 'profit_interest_expenses', '$'],
                ['手續費及傭金支出', 'profit_fees_and_commissions_expenses', '$'],
                ['研發費用', 'profit_research_and_development_expenses', '$'],
                // ['稅金及附加', 'profit_taxes_and_surcharges', '$'],
                ['銷售費用', 'profit_selling_expenses', '$'],
                ['管理費用', 'profit_administrative_expenses', '$'],
                ['財務費用', 'profit_financial_expenses', '$'],
                ['其中：利息費用', 'profit_interest_expenses_in_financial_expenses', '$'],
                ['其中：利息收入', 'profit_interest_revenue_in_financial_expenses', '$'],
              ],
            },
            {
              title: '其他經營收益',
              children: [
                ['加：公允價值變動收益', 'profit_gains_on_fair_value_changes', '$'],
                ['投資收益', 'profit_investment_income', '$'],
                ['資產處置收益', 'profit_asset_disposal_income', '$'],
                ['信用減值損失(新)', 'profit_credit_impairment_losses', '$'],
                ['其他收益', 'profit_other_income', '$'],
              ],
            },
            {
              title: '營業利潤',
              children: [
                ['加：營業外收入', 'profit_non_operating_income', '$'],
                ['減：營業外支出', 'profit_non_operating_expenses', '$'],
              ],
            },
            // {
            //   title: '利潤總額',
            //   children: [
            //     ['減：所得稅費用', 'profit_income_tax_expense', '$'],
            //   ],
            // },
            {
              title: '凈利潤',
              children: [],
              name: 'profit_net_profit',
            },
            {
              title: '(一)按經營持續性分類',
              children: [
                ['持續經營凈利潤', 'profit_profit_from_continuing_operations', '$'],
              ],
            },
            {
              title: '(二)按所有權歸屬分類',
              children: [
                ['歸屬於母公司股東的凈利潤', 'profit_net_profit_attributable_to_owners_of_parent', '$'],
                ['少數股東損益', 'profit_minority_interests_profit_or_loss', '$'],
                ['扣除非經常性損益後的凈利潤', 'profit_net_profit_excluding_non_recurring_items', '$'],
              ],
            },
            {
              title: '每股收益',
              children: [
                ['基本每股收益', 'profit_basic_eps_profit', '$'],
                ['稀釋每股收益', 'profit_diluted_eps_profit', '$'],
              ],
            },
            {
              title: '其他綜合收益',
              children: [
                ['歸屬於母公司股東的其他綜合收益', 'profit_other_comprehensive_income_attributable_to_owners_of_parent', '$'],
              ],
            },
            {
              title: '綜合收益總額',
              children: [
                ['歸屬於母公司所有者的綜合收益總額', 'profit_total_comprehensive_income_attributable_to_owners_of_parent', '$'],
                ['歸屬於少數股東的綜合收益總額', 'profit_total_comprehensive_income_attributable_to_minority_interests', '$'],
                // ['審計意見(境內)', 'profit_audit_opinion_domestic', '$'],
              ],
            },
          ],
        },
        cash: {
          title: '現金流量表',
          filter_flag: 'cash',
          options: [
            {
              title: '經營活動產生的現金流量',
              children: [
                ['銷售商品、提供勞務收到的現金', 'cash_cash_received_from_sales_of_goods_and_services', '$'],
                ['客戶存款和同業存放款項凈增加額', 'cash_net_increase_in_deposits_from_customers_and_interbank', '$'],
                ['收取利息、手續費及傭金的現金', 'cash_cash_received_from_interest_fees_and_commissions', '$'],
                ['收到其他與經營活動有關的現金', 'cash_other_cash_received_relating_to_operating_activities', '$'],
              ],
            },
            {
              title: '經營活動現金流入小計',
              children: [
                ['購買商品、接受勞務支付的現金', 'cash_cash_paid_for_goods_and_services'],
                ['客戶貸款及墊款凈增加額', 'cash_net_increase_in_customer_loans_and_advances'],
                // ['存放中央銀行和同業款項凈增加額', 'cash_net_increase_in_placements_with_central_bank_and_interbank'],
                ['支付利息、手續費及傭金的現金', 'cash_cash_paid_for_interest_fees_and_commissions'],
                ['支付給職工以及為職工支付的現金', 'cash_cash_paid_to_and_on_behalf_of_employees'],
                // ['支付的各項稅費', 'cash_payments_of_various_taxes'],
                ['支付其他與經營活動有關的現金', 'cash_other_cash_payments_relating_to_operating_activities'],
                ['經營活動現金流出的其他項目', 'cash_other_items_outflows_from_operating_activities'],
              ],
            },
            {
              title: '經營活動現金流出小計',
              children: [],
              name: 'cash_total_cash_outflows_from_operating_activities',
            },
            {
              title: '經營活動產生的現金流量凈額',
              children: [],
              name: 'cash_net_cash_flows_from_operating_activities',
            },
            {
              title: '投資活動產生的現金流量',
              children: [
                ['收回投資收到的現金', 'cash_cash_received_from_recovering_investments'],
                ['取得投資收益收到的現金', 'cash_cash_received_from_investment_income'],
                ['處置固定資產、無形資產和其他長期資產收回的現金凈額', 'cash_net_cash_received_from_disposal_of_fixed_intangible_and_other_long_term_assets'],
                ['收到其他與投資活動有關的現金', 'cash_other_cash_received_relating_to_investing_activities'],
              ],
            },
            {
              title: '投資活動現金流入小計',
              children: [
                ['購建固定資產、無形資產和其他長期資產支付的現金', 'cash_cash_paid_for_acquisition_of_fixed_intangible_and_other_long_term_assets'],
                ['投資支付的現金', 'cash_cash_paid_for_investments'],
                ['支付其他與投資活動有關的現金', 'cash_other_cash_payments_relating_to_investing_activities'],
              ],
            },
            {
              title: '投資活動現金流出小計',
              children: [],
              name: 'cash_total_cash_outflows_from_investing_activities',
            },
            {
              title: '投資活動產生的現金流量凈額',
              children: [],
              name: 'cash_net_cash_flows_from_investing_activities',
            },
            // {
            //   title: '籌資活動產生的現金流量',
            //   children: [],
            //   name: 'cash_cash_flows_from_financing_activities',
            // },
            {
              title: '籌資活動現金流入小計',
              children: [
                ['分配股利、利潤或償付利息支付', 'cash_dividends_profits_or_interest_payments'],
                ['其中：子公司支付給少數股東的股利、利潤', 'cash_dividends_and_profits_paid_to_minority_shareholders'],
                ['支付其他與籌資活動有關的現金', 'cash_other_cash_payments_relating_to_financing_activities'],
              ],
            },
            {
              title: '籌資活動現金流出小計',
              children: [],
              name: 'cash_total_cash_outflows_from_financing_activities',
            },
            {
              title: '籌資活動產生的現金流量凈額',
              children: [],
              name: 'cash_net_cash_flows_from_financing_activities',
            },
            {
              title: '期末現金及現金等價物餘額',
              children: [],
              name: 'cash_ending_cash_and_cash_equivalents',
            },
            {
              title: '補充資料',
              children: [
                ['凈利潤', 'cash_net_profit'],
                // ['固定資產和投資性房地產折舊', 'cash_depreciation_of_fixed_assets_and_investment_properties'],
                // ['其中；固定資產折舊、油氣資產折耗、生產性生物資產折舊', 'cash_depreciation_of_fixed_assets_oil_gas_assets_and_biological_assets'],
                ['無形資產攤銷', 'cash_amortization_of_intangible_assets'],
                ['長期待攤費用攤銷', 'cash_amortization_of_long_term_deferred_expenses'],
                ['處置固定資產、無形資產和其他長期資產的損失', 'cash_losses_on_disposal_of_fixed_intangible_and_other_long_term_assets'],
                ['固定資產報廢損失', 'cash_losses_on_fixed_assets_retirement'],
                ['公允價值變動損失', 'cash_losses_on_fair_value_changes'],
                ['財務費用', 'cash_financial_expenses'],
                ['投資損失', 'cash_investment_losses'],
                // ['遞延所得稅', 'cash_deferred_income_tax'],
                // ['其中：遞延所得稅資產減少', 'cash_decrease_in_deferred_tax_assets'],
                // ['遞延所得稅負債增加', 'cash_increase_in_deferred_tax_liabilities'],
                ['存貨的減少', 'cash_decrease_in_inventory'],
                ['經營性應收項目的減少', 'cash_decrease_in_operating_receivables'],
                ['經營性應付項目的增加', 'cash_increase_in_operating_payables'],
              ],
            },
            {
              title: '經營活動產生的現金流量凈額',
              children: [
                ['現金的期末餘額', 'cash_ending_cash_balance'],
                ['減：現金的期初餘額', 'cash_less_beginning_cash_balance'],
                ['加：現金等價物的期末餘額', 'cash_add_ending_cash_equivalents'],
              ],
            },
            {
              title: '現金及現金等價物的凈增加額',
              children: [
                // ['審計意見(境內)', 'cash_audit_opinion_domestic_cash_flow'],
              ],
              name: 'cash_audit_opinion_domestic_cash_flow',
            },
          ],
        },
      }
    };
  },
  components: {

  },
  mounted() {

  },
  created() {
    EventBus.$on("show-finacialreport", () => {
      this.showFinacialReport = true;
      this.activeTab = 'main';
    });

    EventBus.$on("update-finacialreport", data => {
      this.subjects = data.subjects;
      this.records = data.records;
    });
  },
  methods: {
    displayRecordValue(k, idx) {
      if (!(k in this.records)) {
        return '--';
      }

      if (this.records[k] === null || this.records[k].length == 0 || typeof this.records[k][idx] === 'undefined' || this.records[k][idx] === null) {
        return '--';
      }

      return this.records[k][idx];
    },
    displayLeftRecordValue(k) {
      let value = this.displayRecordValue(k, 0);

      if (value == '--') {
        return value;
      }

      return this.unitNumberFormat(value);
    },
    displayRightRecordValue(k) {
      let value = this.displayRecordValue(k, 1);

      if (value == '--') {
        return value;
      }

      return this.unitNumberFormat(value);
    },
    displayRecordUnit(k, idx, unit) {
      let value = this.displayRecordValue(k, idx);

      if (value == '--') {
        return '';
      }

      if (unit === '%') {
        return '%';
      }

      return this.unitFormat(value);
    },
    displayLeftRecordUnit(k, unit) {
      return this.displayRecordUnit(k, 0, unit);
    },
    displayRightRecordUnit(k, unit) {
      return this.displayRecordUnit(k, 1, unit);
    },
    unitFormat(v) {
      if (Math.abs(v) > 100000000) {
        return '億';
      } else if (Math.abs(v) > 10000) {
        return '萬';
      }

      return '';
    },
    unitNumberFormat(v) {

      if (typeof v === 'undefined' || isNaN(v)) {
        v = 0;
      }

      let d = 1;
      if (Math.abs(v) > 100000000) {
        // 億
        d = 100000000;
      // } else if (v > 1000000) {
      //   // 百萬
      //   d = 1000000;
      } else if (Math.abs(v) > 10000) {
        // 萬
        d = 10000;
      }

      return this.numberFormat(v, d);
    },
    numberFormat(v, d) {

      if (typeof v === 'undefined' || isNaN(v)) {
        v = 0;
      }

      if (typeof d === 'undefined' || isNaN(d)) {
        d = 1;
      }

      let n = Math.floor(v / d * 100) / 100;

      return new Intl.NumberFormat().format(n);
    },
  }
}
</script>

<style lang="scss" scoped>
.FinacialReport {
  width: 100%;
  color: #111827;
  background-color: #FAFAFA;

  .page-nav {
    width: 100%;
    height: 100px;

    header {
      border-top-left-radius: 1em;
      border-top-right-radius: 1em;
      width: 100%;
      height: 100px;
      padding: 0 30px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #FAFAFA;
      position: fixed;
      left: 0;
      z-index: 1;

      i {
        width: 50px;
      }

      span img {
        height: 1em;
        margin-right: .5em;
      }
    }
  }
}
</style>
<template>
  <van-popup v-model="showChartList" position="bottom" :style="{ height: '90%' }">
    <div class="Stock">
      <div class="page-nav">
        <header :style="{ 'z-index': 999 }">
          <template>
            <i>

            </i>
            <span> <img :src="item.logo_url" />{{ item.name }}</span>
            <i>
              <van-icon name="cross" @click="showChartList = false" />
            </i>
          </template>
        </header>
      </div>
      <hr class="my-0" />
      <div class="page-content">
        <div class="card p-4 pt-6 border-radius-3">
          <span class="mini-text chart-tabs bg-light-blue">
            <span class="text-center tab-item" :data-active="tab.price.filter_duration == 1 ? 1 : 0"
              @click="tab.price.filter_duration = 1; refreshPriceChart();">近6時</span>
            <span class="text-center tab-item" :data-active="tab.price.filter_duration == 7 ? 1 : 0"
              @click="tab.price.filter_duration = 7; refreshPriceChart();">7天</span>
            <span class="text-center tab-item" :data-active="tab.price.filter_duration == 15 ? 1 : 0"
              @click="tab.price.filter_duration = 15; refreshPriceChart();">15天</span>
            <span class="text-center tab-item" :data-active="tab.price.filter_duration == 30 ? 1 : 0"
              @click="tab.price.filter_duration = 30; refreshPriceChart();">30天</span>
          </span>
          <div :style="'overflow-x: auto;'">
            <highcharts :options="pricelistChartOptions"></highcharts>
          </div>
        </div>

        <div class="card p-4 pt-6 border-radius-3">
          <span class="mini-text chart-tabs bg-light-blue">
            <span class="text-center tab-item" :data-active="tab.order.filter_duration == 7 ? 1 : 0"
              @click="tab.order.filter_duration = 7; refreshOrderChart();">7天</span>
            <span class="text-center tab-item" :data-active="tab.order.filter_duration == 15 ? 1 : 0"
              @click="tab.order.filter_duration = 15; refreshOrderChart();">15天</span>
            <span class="text-center tab-item" :data-active="tab.order.filter_duration == 30 ? 1 : 0"
              @click="tab.order.filter_duration = 30; refreshOrderChart();">30天</span>
          </span>
          <highcharts :options="orderlistChartOptions"></highcharts>
        </div>

        <div class="card p-4 pt-6 border-radius-3">
          <span class="mini-text chart-tabs bg-light-blue">
            <span class="text-center tab-item" :data-active="tab.register.filter_duration == 7 ? 1 : 0"
              @click="tab.register.filter_duration = 7; refreshRegisterChart();">7天</span>
            <span class="text-center tab-item" :data-active="tab.register.filter_duration == 15 ? 1 : 0"
              @click="tab.register.filter_duration = 15; refreshRegisterChart();">15天</span>
            <span class="text-center tab-item" :data-active="tab.register.filter_duration == 30 ? 1 : 0"
              @click="tab.register.filter_duration = 30; refreshRegisterChart();">30天</span>
          </span>
          <highcharts :options="registerChartOptions"></highcharts>
        </div>
      </div>
    </div>
  </van-popup>
</template>

<script>
// import chart from './chart.vue';
import Highcharts from 'highcharts';
import { EventBus } from '../../event-bus';

export default {
  name: "Stock",
  props: {
    item: {
      required: true,
      default: function () {
        return {};
      },
    },
    // isDisplay: {
    //   required: true,
    //   default: function () {
    //     return false;
    //   },
    // },
  },
  data() {
    let parent = this;

    return {
      showChartList: false,
      tab: {
        price: {
          filter_duration: 1,
        },
        order: {
          filter_duration: 7,
        },
        register: {
          filter_duration: 7,
        }
      },
      pricelistChartOptions: {
        time: {
          useUTC: false,
        },

        tooltip: {
          xDateFormat: '%m/%d %H:%M',
        },

        chart: {
          height: (window.innerHeight ||
            document.documentElement.clientHeight ||
            document.body.clientHeight) / 4,
        },
        title: {
          text: this.renderNavbar('市價走勢圖'),
          align: 'left',
          useHTML: true,
        },
        xAxis: {
          scrollbar: {
            enabled: true,
          },
          min: 0,
          max: 0,
          showLastLabel: true,
          showFirstLabel: true,
          type: 'datetime',
          labels: {
            formatter: function () {
              var date = new Date(this.value);

              if (parent.tab.price.filter_duration > 1) {
                return `${date.getMonth() + 1}/${date.getDate()}`;
              }

              var today = new Date();

              var hours = date.getHours();
              var minutes = "0" + date.getMinutes();

              var formattedTime = hours + ':' + minutes.substr(-2);

              if (today.getDay() != date.getDay()) {
                formattedTime = `${date.getMonth() + 1}/${date.getDate()} ` + formattedTime
              }

              return formattedTime;
            },
          },
        },
        yAxis: {
          title: false
        },
        legend: false,
        plotOptions: {
          area: {
            fillColor: {
              linearGradient: {
                x1: 0,
                y1: 0,
                x2: 0,
                y2: 1
              },
              stops: [
                [0, Highcharts.getOptions().colors[0]],
                [
                  1,
                  Highcharts.color(Highcharts.getOptions().colors[0])
                    .setOpacity(0).get('rgba')
                ]
              ]
            },
            marker: {
              radius: 2
            },
            lineWidth: 1,
            states: {
              hover: {
                lineWidth: 1
              }
            },
            threshold: null
          }
        },
        series: [{
          type: 'area',
          name: '市價',
          data: []
        }]
      },
      orderlistChartOptions: {
        lang: {
          decimalPoint: '.',
          thousandsSep: ','
        },
        time: {
          useUTC: false,
        },

        tooltip: {
          xDateFormat: '%m/%d %H:%M',
          // formatter: function () {
          //   var date = new Date(this.x);

          //   let hours = date.getHours();
          //   let minutes = "0" + date.getMinutes();

          //   var formattedTime = `${date.getMonth() + 1}/${date.getDate()} `+ hours + ':' + minutes.substr(-2);

          //   let html = formattedTime + '<br/>';
          //   html += parent.unitNumberFormat(this.y) + parent.unitFormat(this.y) + '人'
          //   return html;
          // }
        },

        chart: {
          height: (window.innerHeight ||
            document.documentElement.clientHeight ||
            document.body.clientHeight) / 4,
        },
        title: {
          text: this.renderNavbar('訂單成交走勢圖'),
          align: 'left',
          useHTML: true,
        },
        xAxis: {
          scrollbar: {
            enabled: true,
          },
          showLastLabel: true,
          showFirstLabel: true,

          type: 'datetime',
          labels: {
            formatter: function () {

              var date = new Date(this.value);

              return `${date.getMonth() + 1}/${date.getDate()}`;
            },
          },
        },
        yAxis: {
          title: false,
          labels: {
            formatter: function () {
              return parent.unitNumberFormat(this.value) + parent.unitFormat(this.value)
            },
          }
        },
        legend: false,
        plotOptions: {
          area: {
            fillColor: {
              linearGradient: {
                x1: 0,
                y1: 0,
                x2: 0,
                y2: 1
              },
              stops: [
                [0, Highcharts.getOptions().colors[0]],
                [
                  1,
                  Highcharts.color(Highcharts.getOptions().colors[0])
                    .setOpacity(0).get('rgba')
                ]
              ]
            },
            marker: {
              radius: 2
            },
            lineWidth: 1,
            states: {
              hover: {
                lineWidth: 1
              }
            },
            threshold: null
          }
        },
        series: [{
          type: 'area',
          name: '訂單數',
          data: []
        }]
      },
      registerChartOptions: {
        time: {
          useUTC: false,
        },

        chart: {
          type: 'column',
          height: (window.innerHeight ||
            document.documentElement.clientHeight ||
            document.body.clientHeight) / 4,
        },
        title: {
          text: this.renderNavbar('每日用戶註冊走勢圖'),
          align: 'left',
          useHTML: true,
        },
        xAxis: {
          categories: [],
          crosshair: true,
        },
        yAxis: {
          min: 0,
          title: false,
          labels: {
            formatter: function () {
              return parent.unitNumberFormat(this.value) + parent.unitFormat(this.value)
            },
          },
        },
        legend: false,
        plotOptions: {
          column: {
            pointPadding: 0.2,
            borderWidth: 0
          }
        },
        tooltip: {
          // formatter: function () {
          //   let html = this.x + '<br/>';
          //   html += parent.unitNumberFormat(this.y) + parent.unitFormat(this.y) + '人'
          //   return html;
          // }
        },
        series: [
          {
            name: '註冊人數',
            data: [406292, 260000, 107000, 68300, 27500, 14500]
          }
        ]
      }
    };
  },
  components: {
    // chart,
  },
  mounted() {

  },
  created() {
    EventBus.$on("show-chartlist", () => {
      this.showChartList = true;

      this.tab.price.filter_duration = 1;
      this.tab.order.filter_duration = 7;
      this.tab.register.filter_duration = 7;
    });

    EventBus.$on("update-chart-all", data => {
      EventBus.$emit("update-chart-price", data);
      EventBus.$emit("update-chart-order", data);
      EventBus.$emit("update-chart-register", data);
    });

    EventBus.$on("update-chart-price", data => {
      this.pricelistChartOptions.xAxis.min = data.pricelist[0][0];
      this.pricelistChartOptions.xAxis.max = data.pricelist[data.pricelist.length - 1][0];
      this.pricelistChartOptions.series[0].data = data.pricelist;
    });

    EventBus.$on("update-chart-order", data => {
      this.orderlistChartOptions.series[0].data = data.orderlist;
    });

    EventBus.$on("update-chart-register", data => {
      // this.registerlistChartOptions.series[0].data = data.registerlist;
      this.rearrangeRegisterList(data.registerlist);
    });
  },
  methods: {
    unitFormat(v) {
      if (v > 100000000) {
        return '億';
      } else if (v > 10000) {
        return '萬';
      }

      return '';
    },
    unitNumberFormat(v) {

      if (typeof v === 'undefined' || isNaN(v)) {
        v = 0;
      }

      let d = 1;
      if (v > 100000000) {
        // 億
        d = 100000000;
        // } else if (v > 1000000) {
        //   // 百萬
        //   d = 1000000;
      } else if (v > 10000) {
        // 萬
        d = 10000;
      }

      return this.numberFormat(v, d);
    },
    numberFormat(v, d) {

      if (typeof v === 'undefined' || isNaN(v)) {
        v = 0;
      }

      if (typeof d === 'undefined' || isNaN(d)) {
        d = 1;
      }

      let n = Math.floor(v / d * 100) / 100;

      return new Intl.NumberFormat().format(n);
    },
    openStockChart() {
      // this.showChartList = true;
    },
    refreshPriceChart() {
      EventBus.$emit("refresh-chart-duration", {
        stock_id: this.item.id,
        filter_type: 'price',
        filter_duration: this.tab.price.filter_duration,
      });
    },
    refreshOrderChart() {
      EventBus.$emit("refresh-chart-duration", {
        stock_id: this.item.id,
        filter_type: 'order',
        filter_duration: this.tab.order.filter_duration,
      });
    },
    refreshRegisterChart() {
      EventBus.$emit("refresh-chart-duration", {
        stock_id: this.item.id,
        filter_type: 'register',
        filter_duration: this.tab.register.filter_duration,
      });
    },
    renderNavbar(text) {
      return `<p class="text-black mini-text" style="width: 90%;">${text}
                
              </p>
              <br/>`;
    },
    rearrangeRegisterList(item) {
      let rdate = [];
      let rcount = [];

      let keys = Object.keys(item);
      keys.sort().forEach(val => {
        rcount.push(item[val]);
        val = val.replace(/\d{4}-/, '').replace(/^0/, '').replace('-0', '-').replace('-', '/');
        rdate.push(val);
      });

      this.registerChartOptions.xAxis.categories = rdate;
      this.registerChartOptions.series[0].data = rcount;
    },
  },
};
</script>

<style lang="scss" scoped>
.Stock {
  width: 100%;
  color: #111827;
  background-color: #FAFAFA;

  .page-nav {
    width: 100%;
    height: 100px;

    header {
      border-top-left-radius: 1em;
      border-top-right-radius: 1em;
      width: 100%;
      height: 100px;
      padding: 0 30px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #FAFAFA;
      position: fixed;
      left: 0;
      z-index: 1;

      i {
        width: 50px;
      }

      span img {
        height: 1em;
        margin-right: .5em;
      }
    }
  }
}
</style>